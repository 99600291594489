import { createAsyncThunk } from '@reduxjs/toolkit';
import { ImageUrlService } from 'services/image-url.service';

import { ImageUrlRequest } from 'types/request/imageUrl.interface';

export const getImageUrls = createAsyncThunk('image-url/get', async () => {
  return ImageUrlService.get();
});

export const imageUrlRequestAction = createAsyncThunk(
  'image-url/create',
  async (data: ImageUrlRequest) => {
    return ImageUrlService.imageUrlRequest(data);
  }
);

export const getImageUrlById = createAsyncThunk(
  'image-url/get-by-id',
  async (id: string) => {
    return ImageUrlService.getById(id);
  }
);

export const deleteImageUrl = createAsyncThunk(
  'image-url/delete',
  async (id: string) => {
    return ImageUrlService.imageUrlRemove(id);
  }
);
