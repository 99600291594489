import Index from 'pages';
import Login from 'pages/Login';
import { Navigate, Route, Routes as RoutesFromRouter } from 'react-router-dom';

import FullLayout from 'design-system/Layouts/FullLayout';
import JustTheLogoLayout from 'design-system/Layouts/JustTheLogo';

import BuyerPage from 'modules/Buyer/pages';
import BuyerRoutes from 'modules/Buyer/types/routes.enum';

import RestorePassword from 'modules/Commerce/components/RestorePassword';
import CommercePage from 'modules/Commerce/pages';
import AnnulmentPage from 'modules/Commerce/pages/Annulment';
import { CashRegistrations } from 'modules/Commerce/pages/CashRegistrations';
import ForgotPassword from 'modules/Commerce/pages/ForgotPassword';
import HomePage from 'modules/Commerce/pages/Home';
import { ImageUrlPage } from 'modules/Commerce/pages/ImageUrl';
import SellPage from 'modules/Commerce/pages/Sell';
import TransactionsPage from 'modules/Commerce/pages/Transactions';
import { TransactionsLotClose } from 'modules/Commerce/pages/TransactionsLotClose';
import CommerceRoutes from 'modules/Commerce/types/routes.enum';

import Roles from 'types/roles.enum';

import CommerceQRModal from './CommerceQRModal';
import ProtectedRoute from './ProtectedRoute';

const COMMERCE = 'commerce';

const Routes = () => {
  return (
    <RoutesFromRouter>
      <Route
        path={`/${COMMERCE}/${CommerceRoutes.FORGOT_PASSWORD}`}
        element={<ForgotPassword />}
      />
      <Route element={<JustTheLogoLayout />}>
        <Route path="/" element={<Index />} />
        <Route path="/login" element={<Login />} />
        <Route
          path={`/${COMMERCE}/${CommerceRoutes.LOGIN}`}
          element={<Login forCommerce />}
        />
      </Route>

      <Route
        path={`/${COMMERCE}`}
        element={
          <ProtectedRoute roles={[Roles.COMMERCE]}>
            <>
              <CommerceQRModal />
              <FullLayout roles={[Roles.COMMERCE]} />
            </>
          </ProtectedRoute>
        }>
        <Route path={CommerceRoutes.INDEX} element={<CommercePage />} />
        <Route path={CommerceRoutes.SELL} element={<SellPage />} />
        <Route path={CommerceRoutes.IMAGE_URL} element={<ImageUrlPage />} />
        <Route
          path={CommerceRoutes.TRANSACTIONS}
          element={
            <ProtectedRoute roles={[Roles.COMMERCE]}>
              <TransactionsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={CommerceRoutes.TRANSACTIONS_LOT}
          element={
            <ProtectedRoute roles={[Roles.COMMERCE]}>
              <TransactionsLotClose />
            </ProtectedRoute>
          }
        />
        <Route
          path={CommerceRoutes.CASH_REGISTER_CREATION}
          element={
            <ProtectedRoute roles={[Roles.COMMERCE]}>
              <CashRegistrations />
            </ProtectedRoute>
          }
        />
        <Route
          path={CommerceRoutes.RESTORE_PASSWORD}
          element={
            <ProtectedRoute roles={[Roles.COMMERCE, Roles.ADMIN_COMMERCE]}>
              <RestorePassword />
            </ProtectedRoute>
          }
        />
        <Route path={CommerceRoutes.ANNULMENT} element={<AnnulmentPage />} />
        <Route path={CommerceRoutes.HOME} element={<HomePage />} />
      </Route>

      <Route
        path="/buyer"
        element={
          <ProtectedRoute roles={[Roles.BUYER]}>
            <FullLayout roles={[Roles.BUYER]} />
          </ProtectedRoute>
        }>
        <Route path={BuyerRoutes.INDEX} element={<BuyerPage />} />
      </Route>

      <Route path={'*'} element={<Navigate to="/" />} />
    </RoutesFromRouter>
  );
};
export default Routes;
