import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useEffect, useState } from 'react';
import { ImageUrlService } from 'services/image-url.service';

import { Box } from '@mui/material';

import TitleLabel from 'design-system/Layouts/TitleLabels';

import { me } from 'redux/commerce/commerce.action';

import { PromotionsCarousel } from './PromotionsCarousel';

export const NewsPromotions: React.FC = () => {
  const dispatch = useAppDispatch();
  const [promotions, setPromotions] = useState<any>([]);

  const fetchPromotions = async () => {
    try {
      const urls = await ImageUrlService.get();
      const promotionsUrls = urls.map((url) => url.url);
      setPromotions(promotionsUrls);
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(me());
    fetchPromotions();
  }, [dispatch]);

  return (
    <Box sx={{ minWidth: '100%' }}>
      <TitleLabel>Novedades</TitleLabel>
      {promotions && promotions.length > 0 && (
        <PromotionsCarousel promotions={promotions} />
      )}
    </Box>
  );
};
