import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useEffect, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Box,
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
  useTheme
} from '@mui/material';

import { Colors } from 'design-system/theme';

import { deleteImageUrl, getImageUrls } from 'redux/imageUrl/imageUrl.action';
import { imageUrlSelector } from 'redux/imageUrl/imageUrl.selector';
import 'redux/transaction/transaction.slice';

const tableStyles = {
  border: 'none',
  flex: 1,
  color: Colors.gray
};

const StyledTableCell = styled(TableCell)(() => ({
  whiteSpace: 'nowrap',
  width: 'max-content',
  [`&.${tableCellClasses.head}`]: {
    ...tableStyles,
    fontSize: 14,
    fontWeight: 600
  },
  [`&.${tableCellClasses.body}`]: {
    ...tableStyles,
    fontSize: 14,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  margin: 0,
  padding: '0',
  borderRadius: '0.5rem',
  border: `1px solid ${theme.palette.primary.main}`,
  justifyContent: 'space-around'
}));

const StyledTableRowBody = styled(TableRow)({
  display: 'flex',
  width: '100%',
  margin: 0,
  padding: '0',
  borderBottom: '1px solid'
});

export const ImageUrlList: React.FC = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [clickedImageUrlId, setClickedImageUrlId] = useState<string | null>('');
  const { urls, loading } = useAppSelector(imageUrlSelector);

  const headerOptions = ['Url', 'Eliminar'];

  const handleDeleteClick = (imageUrlId: string) => {
    handleDelete(imageUrlId);
    setClickedImageUrlId(imageUrlId);
  };

  const handleDelete = async (imageUrlId: string) => {
    const resp = await dispatch(deleteImageUrl(imageUrlId));

    if (resp.meta.requestStatus === 'fulfilled') {
      dispatch(getImageUrls());
    }

    setClickedImageUrlId(null);
  };

  useEffect(() => {
    dispatch(getImageUrls());
  }, [dispatch]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '65vh',
        backgroundColor: theme.palette.background.paper
      }}>
      <TableContainer sx={{ overflowY: 'auto', flexGrow: 1, minWidth: '100%' }}>
        <Table aria-label="Transaction-tables" sx={{ minWidth: '100%' }}>
          <TableHead>
            <StyledTableRow>
              {headerOptions.map((header) => (
                <StyledTableCell key={header} align="center">
                  {header}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {urls && !loading
              ? urls.map((url) => (
                  <StyledTableRowBody key={url.id} sx={{}}>
                    <StyledTableCell align="center">{url.url}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Button onClick={() => handleDeleteClick(url.id)}>
                        {clickedImageUrlId !== url.id ? (
                          <CancelOutlinedIcon />
                        ) : (
                          <CancelIcon />
                        )}
                      </Button>
                    </StyledTableCell>
                  </StyledTableRowBody>
                ))
              : Array(7)
                  .fill(1)
                  .map((a, i) => (
                    <StyledTableRowBody key={i} sx={{}}>
                      <StyledTableCell align="center">
                        <Skeleton sx={{ fontSize: '0.875rem' }} />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Box width="3rem">
                          <Skeleton
                            sx={{ margin: 'auto' }}
                            variant="circular"
                            width={24}
                            height={24}
                          />
                        </Box>
                      </StyledTableCell>
                    </StyledTableRowBody>
                  ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
