import { FormikProps } from 'formik';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import { Box, Grid, TextField, useTheme } from '@mui/material';

import TitleLabel from 'design-system/Layouts/TitleLabels';

interface ImageUrlProps {
  formik: FormikProps<any>;
}
const ImageUrlForm: React.FC<ImageUrlProps> = ({ formik }) => {
  const theme = useTheme();
  const isLargeScreen = useIsLargeScreen();
  return (
    <Box
      sx={{
        marginTop: '0',
        margin: 'auto',
        marginBottom: '1.5rem',
        border: `1px solid ${theme.palette.primary.main}`,
        padding: '1rem',
        paddingBottom: '1rem',
        borderRadius: '0.625rem',
        background: theme.palette.background.default
      }}>
      <TitleLabel>Url de la imagen</TitleLabel>
      <Grid container justifyContent="space-between">
        <Grid item sx={{ my: 1 }} xs={12} md={12}>
          <TextField
            autoFocus
            label=""
            size={isLargeScreen ? undefined : 'small'}
            sx={{
              width: '100%',
              fontSize: isLargeScreen ? undefined : 10
            }}
            value={formik.values.url}
            onChange={(e) => {
              formik.setFieldValue('url', e.target.value);
            }}
            helperText={formik.errors.url as string}
            error={!!formik.errors.url}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ImageUrlForm;
