import { ImageUrl } from 'redux/imageUrl/types/imageUrl.type';

import { ImageUrlRequest } from 'types/request/imageUrl.interface';
import { IApiResponse } from 'types/responses/apiResponse.interface';

import { api } from './instance';

export const ImageUrlService = {
  get: async () => {
    const res = await api.get<IApiResponse<ImageUrl[]>>('/image-url');

    return res.data.data;
  },
  getById: async (id: string) => {
    const res = await api.get<IApiResponse<ImageUrl>>(`/image-url/${id}`);

    return res.data.data;
  },
  imageUrlRequest: async (imageUrlData: ImageUrlRequest) => {
    const res = await api.post<IApiResponse<ImageUrl>>(
      '/image-url',
      imageUrlData
    );
    return res.data.data;
  },
  imageUrlRemove: async (id: string) => {
    const res = await api.delete<IApiResponse<ImageUrl>>(`/image-url/${id}`);

    return res.data.data;
  }
};
