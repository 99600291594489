import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  deleteImageUrl,
  getImageUrlById,
  getImageUrls,
  imageUrlRequestAction
} from './imageUrl.action';
import { ImageUrl } from './types/imageUrl.type';
import { IImageUrlState } from './types/imageUrlState.interface';

const initialState: IImageUrlState = {
  loading: false,
  urls: []
};

const imageUrlSlice = createSlice({
  name: 'image-url',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getImageUrls.fulfilled,
        (state, action: PayloadAction<ImageUrl[]>) => {
          state.urls = action.payload;
          state.errorMessage = undefined;
          state.loading = false;
        }
      )
      .addCase(getImageUrls.rejected, (state) => {
        state.errorMessage = 'Ops something went wrong';
        state.urls = [];
        state.loading = false;
      })
      .addCase(getImageUrls.pending, (state) => {
        state.loading = true;
      })

      .addCase(imageUrlRequestAction.fulfilled, (state) => {
        state.errorMessage = undefined;
        state.loading = false;
      })
      .addCase(imageUrlRequestAction.rejected, (state) => {
        state.errorMessage = 'No fue posible agregar la url';
        state.loading = false;
      })
      .addCase(imageUrlRequestAction.pending, (state) => {
        state.loading = true;
      })

      .addCase(
        getImageUrlById.fulfilled,
        (state, action: PayloadAction<ImageUrl>) => {
          state.urls = [action.payload];
        }
      )

      .addCase(deleteImageUrl.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteImageUrl.rejected, (state) => {
        state.errorMessage = 'No fue posible eliminar la url';
        state.loading = false;
      })
      .addCase(deleteImageUrl.fulfilled, (state) => {
        state.errorMessage = undefined;
        state.loading = false;
      });
  }
});

export default imageUrlSlice.reducer;
