import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Typography, useTheme } from '@mui/material';

import ModalApprove from 'components/ModalApproved';
import ModalLoader from 'components/ModalLoader';
import ModalReject from 'components/ModalRejected';

import Modal from 'design-system/Modal';
import { Colors } from 'design-system/theme';

import { setModalOpen } from 'redux/commerce/commerce.slice';
import {
  approveRequestAction,
  getPendingTransactions,
  getTransactions,
  rejectRequestAction
} from 'redux/transaction/transaction.action';
import { transactionSelector } from 'redux/transaction/transaction.selector';
import { setTransactionModalIsOpen } from 'redux/transaction/transaction.slice';
import { TransactionModalStatus } from 'redux/transaction/types/transactionState.interface';

import PendingTransactionMobile from './PendingTransactionMobile';

export const TransactionsMobile: React.FC = () => {
  const [isContentVisible, setIsContentVisible] = useState(false);
  const theme = useTheme();
  const isLargeScreen = useIsLargeScreen();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const updateTransactions = () => {
    dispatch(getTransactions(queryString.parse(location.search)));
    dispatch(getPendingTransactions());
  };

  const onApprove = async (id: string) => {
    const resp = await dispatch(approveRequestAction(id));
    if (resp.meta.requestStatus === 'fulfilled') {
      updateTransactions();
    }
  };
  const onReject = async (id: string) => {
    const resp = await dispatch(rejectRequestAction(id));
    if (resp.meta.requestStatus === 'fulfilled') {
      updateTransactions();
      setModalOpen(false);
    }
  };
  const { pendingTransactions, transactionModal } =
    useAppSelector(transactionSelector);

  useEffect(() => {
    dispatch(getPendingTransactions());
  }, [dispatch]);

  const handleClose = () => {
    dispatch(setTransactionModalIsOpen(false));
  };

  const lastTransaction = pendingTransactions[pendingTransactions.length - 1];

  return (
    <Box mt="1.875rem">
      {pendingTransactions.length > 0 && (
        <Box
          sx={{
            marginTop: '1rem',
            padding: '0.25rem 1rem',
            borderRadius: '0.25rem',
            backgroundColor: theme.palette.primary.main
          }}>
          {pendingTransactions.length > 0 && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Typography
                color={Colors.white}
                variant="h3"
                fontSize={'0.825rem'}
                fontWeight={isLargeScreen ? 700 : 600}>
                Compras pendientes de aprobación
              </Typography>
              <Button
                onClick={() => setIsContentVisible(!isContentVisible)}
                style={{ marginRight: '-1rem' }}>
                {isContentVisible ? (
                  <KeyboardArrowUpIcon style={{ color: 'white' }} />
                ) : (
                  <KeyboardArrowDownIcon style={{ color: 'white' }} />
                )}
              </Button>
            </Box>
          )}
          {isContentVisible &&
            pendingTransactions.map((pendingTransaction) => (
              <Box
                key={pendingTransaction.id}
                my={1}
                sx={{ backgroundColor: theme.palette.primary.main }}>
                <PendingTransactionMobile
                  pendingTransaction={pendingTransaction}
                />
              </Box>
            ))}
        </Box>
      )}
      <Modal open={transactionModal.isOpen} closeLabel="SALIR">
        {transactionModal.status === TransactionModalStatus.WAITING && (
          <ModalLoader LoaderLabel="Esperando Autorización" />
        )}
        {transactionModal.status === TransactionModalStatus.APPROVED && (
          <ModalApprove
            ApproveLabel="Operación Autorizada"
            handleClose={() => {
              handleClose();
            }}
          />
        )}
        {transactionModal.status ===
          TransactionModalStatus['REJECTED-BY-BUYER'] && (
          <ModalReject
            rejectLabel="Operación rechazada por el cliente"
            handleClose={() => {
              handleClose();
            }}
          />
        )}
        {transactionModal.status ===
          TransactionModalStatus['REJECTED-BY-CD'] && (
          <ModalReject
            rejectLabel="Operación rechazada por Créditos Directos"
            extraLabel="Más info al 18918"
            handleClose={() => {
              handleClose();
            }}
          />
        )}
      </Modal>
      {lastTransaction && (
        <Box display="flex" mt={2} gap={2}>
          <Button
            variant="contained"
            onClick={() => onApprove(lastTransaction.id)}>
            Aprobar
          </Button>
          <Button
            variant="outlined"
            onClick={() => onReject(lastTransaction.id)}
            sx={{ backgroundColor: theme.palette.background.paper }}>
            Rechazar
          </Button>
        </Box>
      )}
    </Box>
  );
};
