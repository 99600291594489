export enum CommerceRoutes {
  INDEX = '',
  SELL = 'sell',
  TRANSACTIONS = 'transactions',
  ANNULMENT = 'annulment',
  LOGIN = 'login',
  HOME = 'home',
  TRANSACTIONS_LOT = 'transactions-lot',
  FORGOT_PASSWORD = 'forgot-password',
  CASH_REGISTER_CREATION = 'cash-registration',
  RESTORE_PASSWORD = 'restore-password',
  IMAGE_URL = 'image-url'
}
export default CommerceRoutes;
