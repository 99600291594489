import { Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useIsLargeScreen from 'hooks/useIsLargeScreen';

import { Box, Button, Container, Grid, useTheme } from '@mui/material';

import { NewsPromotions } from 'components/NewsPromotions';

import {
  getImageUrls,
  imageUrlRequestAction
} from 'redux/imageUrl/imageUrl.action';

import ImageUrlForm from '../components/ImageUrlForm';
import { ImageUrlList } from '../components/ImageUrlList';

export const ImageUrlPage: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const initialValues = {
    url: ''
  };

  const handleSubmit = async (values: any, actions: any) => {
    const urlLoad = {
      url: String(values.url)
    };

    await dispatch(imageUrlRequestAction(urlLoad));
    dispatch(getImageUrls());
    actions.resetForm();
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        pb: '2rem',
        mt: isLargeScreen ? '7.5rem' : '6rem'
      }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={isLargeScreen ? 2 : 1}
        sx={{
          minHeight: '75vh'
        }}>
        <Grid
          item
          xs={12}
          sx={{
            marginBottom: '1rem',
            background: theme.palette.background.default,
            margin: isLargeScreen ? undefined : '0.5rem'
          }}>
          <NewsPromotions />
        </Grid>
      </Grid>
      <Grid item xs={12} my={isLargeScreen ? undefined : '0.5rem'}>
        <Box
          sx={{
            marginTop: '0',
            margin: 'auto',
            marginBottom: '1.5rem',
            border: `1px solid ${theme.palette.primary.main}`,
            padding: '1rem',
            paddingBottom: '1rem',
            borderRadius: '0.625rem',
            background: theme.palette.background.default
          }}>
          <Grid container justifyContent="space-between">
            <Grid item sx={{ my: 1 }} xs={12} md={12}>
              <Formik
                initialValues={initialValues}
                onSubmit={(values, actions) => handleSubmit(values, actions)}
                validateOnBlur={false}
                validateOnChange={false}>
                {(formik) => (
                  <Form>
                    <ImageUrlForm formik={formik} />
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: '1.5rem',
                        gap: '1rem',
                        fontSize: isLargeScreen ? undefined : 12
                      }}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={formik.isSubmitting}>
                        Agregar
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ImageUrlList />
      </Grid>
    </Container>
  );
};
