import LogoImg from 'assets/imgs/logo.png';
import { Form, Formik } from 'formik';
import { validationDigit } from 'helpers/validateCI';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginRequestType } from 'services/commerce.service';
import * as Yup from 'yup';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography,
  useTheme
} from '@mui/material';

import Label from 'design-system/Label';

import CommerceRoutes from 'modules/Commerce/types/routes.enum';

import { buyerLogin } from 'redux/buyer/buyer.action';
import { buyerSelector } from 'redux/buyer/buyer.selector';
import { commerceLogin } from 'redux/commerce/commerce.action';
import { commerceSelector } from 'redux/commerce/commerce.selector';

import { getLocalStorage, setLocalStorage } from 'utils/localstorage';

interface ILoginProps {
  forCommerce?: boolean;
}

const Login = ({ forCommerce }: ILoginProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const buyerState = useAppSelector(buyerSelector);

  const { loading, errorMessage } = useAppSelector(commerceSelector);
  const [isCashierChecked, setIsCashierChecked] = useState<boolean>(false);

  const isLargeScreen = useIsLargeScreen();
  const buyerUserEmail = getLocalStorage('buyerUserEmail');
  const commerceUser = getLocalStorage('commerceUser');
  const userNro = getLocalStorage('userNro');

  const validationSchema = Yup.object().shape({
    email: forCommerce
      ? Yup.string().required('Usuario es requerido')
      : Yup.string()
          .required('Usuario es requerido')
          .matches(/^[0-9]+$/, 'Ingrese solo números')
          .test('ci_async_validation', 'Cedula incorrecta', (value) => {
            return validationDigit(value) === Number(value[7]);
          }),
    password: Yup.string().required('Contraseña requerida').min(4, '4'),
    affiliate: Yup.string()
  });

  const handleSubmit = async (values: loginRequestType) => {
    if (forCommerce) {
      const resp = await dispatch(commerceLogin(values));
      if (resp.meta.requestStatus === 'fulfilled') {
        if (values.remember) {
          setLocalStorage('userNro', values.affiliate);
          setLocalStorage('commerceUser', values.email);
        }
        navigate(`/commerce/${CommerceRoutes.HOME}`);
      }
    } else {
      const resp = await dispatch(
        buyerLogin({
          email: values.email.slice(0, -1),
          password: values.password,
          remember: values.remember
        })
      );
      if (resp.meta.requestStatus === 'fulfilled') {
        if (values.remember) {
          setLocalStorage('buyerUserEmail', values.email);
        }
        navigate('/buyer');
      }
    }
  };

  return (
    <Box
      sx={{
        widht: isLargeScreen ? undefined : '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Box
        sx={{
          width: isLargeScreen ? 384 + 59 * 2 : '100%',
          maxWidth: isLargeScreen ? '90%' : '100%',
          margin: 'auto',
          border: isLargeScreen
            ? `1px solid ${theme.palette.primary.main}`
            : 'none',
          padding: '3.6875rem',
          borderRadius: '1.5rem',
          background: theme.palette.background.default
        }}>
        {isLargeScreen && (
          <Box
            sx={{
              maxWidth: '8.25rem',
              margin: 'auto',
              marginBottom: '4.25rem'
            }}>
            <img src={LogoImg} alt="logo" width={'100%'} />
          </Box>
        )}

        <Formik
          initialValues={{
            email: forCommerce ? commerceUser ?? '' : buyerUserEmail ?? '',
            password: '',
            affiliate: userNro ? userNro : '',
            remember: false
          }}
          onSubmit={(values) => handleSubmit(values)}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}>
          {(formik) => (
            <Form>
              {forCommerce && (
                <Box mb="1rem" mt="0.75rem">
                  <>
                    <Label
                      color={!!formik.errors.affiliate ? 'error' : undefined}>
                      Número de afiliado
                    </Label>
                    <TextField
                      fullWidth
                      id="affiliate"
                      name="affiliate"
                      placeholder="Ingresar número de afiliado"
                      autoComplete="affiliate"
                      value={formik.values.affiliate}
                      onChange={(e) =>
                        formik.setFieldValue('affiliate', e.target.value)
                      }
                      error={!!formik.errors.affiliate}
                      disabled={isCashierChecked} // Disable the TextField based on the checkbox state
                      sx={{ fontSize: isLargeScreen ? undefined : '10' }}
                    />
                  </>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        size="small"
                        checked={isCashierChecked}
                        onChange={(e) => setIsCashierChecked(e.target.checked)}
                      />
                    }
                    label="Cajero"
                  />
                </Box>
              )}
              <Box mb="1rem" mt="0.75rem">
                <Label color={!!formik.errors.email ? 'error' : undefined}>
                  {isCashierChecked ? 'Email' : 'Usuario'}
                </Label>
                <TextField
                  required
                  fullWidth
                  id="email"
                  name="email"
                  placeholder="Ingresar usuario"
                  autoComplete="email"
                  value={formik.values.email}
                  onChange={(e) =>
                    formik.setFieldValue('email', e.target.value)
                  }
                  autoFocus
                  error={!!formik.errors.email}
                  sx={{ fontSize: isLargeScreen ? undefined : '10' }}
                />
              </Box>
              <Box mb="1rem" mt="0.75rem">
                <Label color={!!formik.errors.password ? 'error' : undefined}>
                  Contraseña
                </Label>
                <TextField
                  required
                  fullWidth
                  placeholder="Ingresar contraseña"
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="password"
                  value={formik.values.password}
                  onChange={(e) =>
                    formik.setFieldValue('password', e.target.value)
                  }
                  error={!!formik.errors.password}
                  sx={{ fontSize: isLargeScreen ? undefined : '10' }}
                />
              </Box>
              <Box minHeight={'0.625rem'} mt={'0.125rem'}>
                {(!!formik.errors.email ||
                  !!formik.errors.password ||
                  !!buyerState.errorMessage ||
                  !!errorMessage) && (
                  <Typography
                    color="error"
                    variant="body1"
                    fontWeight={500}
                    fontSize={'0.625rem'}
                    lineHeight={'0.625rem'}>
                    {!!formik.errors.email
                      ? formik.errors.email
                      : 'Datos ingresados incorrectos.'}
                  </Typography>
                )}
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    size="small"
                    checked={formik.values.remember}
                  />
                }
                name="remember"
                label="Recordarme"
                onChange={formik.handleChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 1,
                  fontSize: isLargeScreen ? undefined : 12,
                  minHeight: '2rem'
                }}>
                {loading || buyerState.loading ? (
                  <CircularProgress
                    color="inherit"
                    size={isLargeScreen ? 14 : 10}
                    sx={{ marginLeft: '1rem' }}
                  />
                ) : (
                  'Log in'
                )}
              </Button>

              {forCommerce && (
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{
                    mt: 2,
                    mb: 1,
                    fontSize: isLargeScreen ? undefined : 12
                  }}
                  onClick={() => {
                    navigate(`/commerce/${CommerceRoutes.FORGOT_PASSWORD}`);
                  }}>
                  Olvidé mi contraseña
                </Button>
              )}
              <Box mt={'0.5rem'}>
                <Label fontWeight={500}>
                  {forCommerce
                    ? '¿No estás afiliado? ¡Hacelo acá!'
                    : '¿No tiene usuario? Regístrese aquí'}
                </Label>
                <Button
                  target="_blank"
                  fullWidth
                  variant="outlined"
                  href={
                    forCommerce
                      ? 'https://www.creditosdirectos.com.uy/comerciantes/'
                      : 'https://creditosdirectos.com.uy:8989/MovilWeb/registro.html'
                  }
                  sx={{
                    mt: 2,
                    mb: 1,
                    fontSize: isLargeScreen ? undefined : 12,
                    minHeight: '2rem'
                  }}>
                  Register
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
export default Login;
